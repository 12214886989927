<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col
            md="12"
            class="d-flex align-items-center justify-content-end"
          >
            <v-date-picker
              v-model="query.startDate"
              type="month"
              value-type="YYYYMM"
              class="el-def"
              style="width:150px"
            />
            <label class="ml-50 mr-50">~</label>
            <v-date-picker
              v-model="query.endDate"
              type="month"
              value-type="YYYYMM"
              class="el-def"
              style="width:150px"
            />
            <b-form-input
              v-model="query.title"
              placeholder="제목"
              class="el-def"
              style="width:200px; margin-right:50px;"
              @keyup.enter="fetchRecords()"
            />
            <b-button
              variant="primary"
              class="btn-search mr-50"
              @click.prevent="fetchRecords()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              총 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="id"
        :items="records"
        :fields="tableColumns"
        :no-local-sorting="true"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onSelectedRecord"
        @sort-changed="onSortingChanged"
      >
        <template #cell(liveDate)="data">
          {{ data.item.startDate | $dateFormatter('YYYY-MM-DD') }} ~ {{ data.item.endDate | $dateFormatter('YYYY-MM-DD') }}
        </template>
        <template #cell(awardUserCount)="data">
          {{ data.value == null ? 0 : data.value }}
        </template>
        <template #cell(createDate)="data">
          {{ data.value | $dateFormatter('YYYY-MM-DD') }}
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="5"
        align="center"
      />
    </b-card>
    <b-card class="mt-2">
      <b-row>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetRecord()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규 등록</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="saveRecord()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <h5>※ 포인트 수여는 저장 후 가능합니다.</h5>
        </b-col>
      </b-row>
      <validation-observer ref="pointAwardRules">
        <b-row class="mt-1">
          <b-col md="6">
            <b-form-group
              label="제목"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="record.title"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="진행시작일"
              label-for="start-date"
            >
              <validation-provider
                #default="{ errors }"
                :rules="{ required: true, before: record.endDate }"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="record.startDate"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="진행종료일"
              label-for="end-date"
            >
              <validation-provider
                #default="{ errors }"
                :rules="{ required: true, after: record.startDate }"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="record.endDate"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="특이사항"
              label-for="memo"
            >
              <b-form-textarea
                id="memo"
                v-model="record.memo"
                rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="mb-50 ml-50">
              수여완료 총 [{{ record.users && record.users.length ? record.users.length : 0 }}] 명
            </h5>
            <b-table
              primary-key="id"
              :items="record.users"
              :fields="userTableColumns"
              sticky-header="200px"
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(createDate)="data">
                <span class="text-nowrap">
                  {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card
      v-show="record.id"
      class="mt-2"
    >
      <b-row>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            포인트 수여
          </h4>
        </b-col>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="primary"
            @click.prevent="downloadUploadSample()"
          >
            업로드 샘플 다운로드
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="awardPoint()"
          >
            포인트 수여하기
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <h5>※ 엑셀 업로드 데이터는 포인트를 수여하지 않으면 저장되지 않습니다.</h5>
          <h5>※ 중복 ID는 기존에 수여된 내역을 확인하지 않으며, 현재 업로드한 데이터 중 중복 ID만 삭제합니다.</h5>
          <h5>※ 중복 ID가 있을시 어느 포인트를 수여할지 알 수 없기 때문에, 모든 중복ID는 삭제합니다.</h5>
          <h5>※ 포인트 수여시 수여 내역은 자동 저장되나, 그 외 다른 항목은 저장 버튼으로 별도 저장해야 합니다.</h5>
          <h5>※ 수여되는 포인트는 서버에 저장된 제목으로 발행되니, 제목을 확정 및 저장 후 포인트 수여하시기 바랍니다.</h5>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          md="2"
          class="pt-50 text-center"
        >
          <h5>엑셀 업로드</h5>
        </b-col>
        <b-col md="3">
          <b-form-file
            v-model="recordSub.attachFile"
            accept=".xlsx"
            @input="uploadAward"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          md="2"
          class="pt-50 text-center"
        >
          <h5>엑셀 업로드 결과</h5>
        </b-col>
        <b-col
          md="4"
          class="pt-50"
        >
          <p>결과 : {{ recordSub.uploadResult }}</p>
          <p>유효하지않은ID(삭제) : {{ recordSub.vaildMessage }}</p>
          <p>중복ID(삭제) : {{ recordSub.duplicateMessage }}</p>
        </b-col>
        <b-col md="6">
          <h5 class="mb-50 ml-50">
            업로드 총 [{{ recordSub.uploadUsers && recordSub.uploadUsers.length ? recordSub.uploadUsers.length : 0 }}] 명
          </h5>
          <b-table
            primary-key="id"
            :items="recordSub.uploadUsers"
            :fields="uploadTableColumns"
            sticky-header="200px"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "@axios"
import dayjs from 'dayjs'
import {
  ref, onMounted, watch, getCurrentInstance,
} from '@vue/composition-api'
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, before, after } from '@validations'
import { BFormFile } from "bootstrap-vue"
import { saveAs } from "file-saver"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  setup() {
    onMounted(() => {
      fetchRecords()
    })
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Query Data
    const query = ref({
      startDate: dayjs().format('YYYYMM'),
      endDate: dayjs().format('YYYYMM'),
      title: null,
    })

    const recordInitState = {
      id: null,
      title: null,
      startDate: null,
      endDate: null,
      memo: null,
      users: [],
    }
    const recordSubInitState = {
      attachFile: null,
      uploadResult: null,
      vaildMessage: null,
      duplicateMessage: null,
      uploadUsers: [],
    }
    const record = ref({ ...recordInitState })
    const recordSub = ref({ ...recordSubInitState })
    const resetRecord = () => {
      Object.assign(record.value, recordInitState)
      Object.assign(recordSub.value, recordSubInitState)
    }
    const resetRecordSub = () => {
      Object.assign(recordSub.value, recordSubInitState)
    }

    const userTableColumns = [
      { key: 'index', label: 'No.' },
      { key: 'userId', label: 'Id' },
      { key: 'point', label: '지급포인트' },
      { key: 'createDate', label: '지급일자' },
    ]

    const uploadTableColumns = [
      { key: 'index', label: 'No.' },
      { key: 'userId', label: 'Id' },
      { key: 'point', label: '지급포인트' },
    ]

    const records = ref([])
    const sortBy = ref('id')
    const sortDesc = ref(true)
    const totalRecords = ref(0)
    const currPage = ref(1)
    const tableColumns = [
      { key: 'id', label: 'No.', sortable: true },
      { key: 'liveDate', label: '진행기간', sortable: false },
      { key: 'title', label: '제목', sortable: true },
      { key: 'awardUserCount', label: '수여인원', sortable: true },
      { key: 'createDate', label: '등록일', sortable: true },
    ]
    watch([currPage], () => {
      fetchRecords()
    })
    const onSelectedRecord = items => {
      const item = items[0]
      if (item && item.id) {
        fetchRecord(item.id)
      }
    }
    const onSortingChanged = ctx => {
      sortBy.value = ctx.sortBy
      sortDesc.value = ctx.sortDesc
      fetchRecords()
    }

    const fetchRecords = () => {
      axios.post('/fa/pointaward/list', {
        search: {
          startDate: query.value.startDate ? dayjs(query.value.startDate).date(1).format('YYYY-MM-DD') : null,
          endDate: query.value.endDate ? dayjs(query.value.endDate).add(1, 'month').date(1).format('YYYY-MM-DD') : null,
          title: query.value.title,
        },
        sort: {
          predicate: sortBy.value,
          reverse: sortDesc.value,
        },
        pagination: {
          number: currPage.value,
          count: 5,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          records.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const fetchRecord = id => {
      axios.get(`/fa/pointaward/${id}`)
        .then(rs => {
          record.value.id = rs.data.id
          record.value.title = rs.data.title
          record.value.startDate = rs.data.startDate
          record.value.endDate = rs.data.endDate
          record.value.memo = rs.data.memo
          fetchRecordUser(id)
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const fetchRecordUser = id => {
      axios.get(`/fa/pointaward/user/${id}`)
        .then(rs => {
          record.value.users = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const downloadUploadSample = () => {
      axios.get(`/fa/pointaward/uploadSample`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])

          const fileName = '포인트수여 업로드샘플파일'

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    const uploadAward = file => {
      if (!file) {
        pushToast('warning', '엑셀이 선택되지 않았습니다.')
        recordSub.value.attachFile = null
        return
      }

      resetRecordSub()

      const formData = new FormData()
      formData.append('file', file)

      axios.post('/fa/pointaward/uploadAward',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.code && rs.data.code === 'success') {
            recordSub.value.uploadResult = '성공'
            recordSub.value.vaildMessage = rs.data.vaildMessage
            recordSub.value.duplicateMessage = rs.data.duplicateMessage
            recordSub.value.uploadUsers = rs.data.users
          } else {
            recordSub.value.uploadResult = `실패 : ${rs.data.errorMessage}`
          }
        })
        .catch(() => {
          pushToast('danger', '엑셀 업로드 중 오류가 발생하였습니다.')
        })
    }

    const awardPoint = () => {
      const awardCount = recordSub.value.uploadUsers.length
      if (awardCount <= 0) {
        pushToast('warning', '수여할 대상이 없습니다.')
        return
      }

      bvModal
        .msgBoxConfirm(`${awardCount}명에게 포인트를 수여하시겠습니까?`, {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) {
            return
          }

          axios.put(`/fa/pointaward/awardPoint/${record.value.id}`, recordSub.value.uploadUsers)
            .then(rs => {
              if (rs.data.code && rs.data.code === 'success') {
                pushToast('success', '포인트가 수여되었습니다.')
                resetRecordSub()
                fetchRecordUser(record.value.id)
                fetchRecords()
              } else {
                pushToast('danger', rs.data.errorMessage)
              }
            })
            .catch(() => {
              pushToast('danger', '포인트 수여 중 오류가 발생하였습니다.')
            })
        })
    }

    const saveRecord = () => {
      const validPromise = refs.pointAwardRules.validate()
      validPromise.then(valid => {
        if (!valid) {
          pushToast('danger', '입력하지 않았거나, 유효하지 않은 데이터가 있습니다.')
          return
        }

        axios.put('/fa/pointaward/save', record.value)
          .then(rs => {
            if (rs.data.id) {
              pushToast('success', '저장하였습니다.')
              fetchRecord(rs.data.id)
            } else {
              pushToast('danger', '데이터 저장에 실패하였습니다.')
            }
            fetchRecords()
          })
          .catch(() => {
            pushToast('danger', '데이터 저장 중 오류가 발생하였습니다.')
          })
      })
    }

    return {
      query,
      record,
      recordSub,
      records,
      tableColumns,
      totalRecords,
      currPage,
      fetchRecords,
      onSelectedRecord,
      onSortingChanged,
      resetRecord,
      saveRecord,
      downloadUploadSample,
      uploadAward,
      awardPoint,
      userTableColumns,
      uploadTableColumns,
      required,
      before,
      after,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
