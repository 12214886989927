var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { md: "12" },
                  },
                  [
                    _c("v-date-picker", {
                      staticClass: "el-def",
                      staticStyle: { width: "150px" },
                      attrs: { type: "month", "value-type": "YYYYMM" },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-50 mr-50" }, [_vm._v("~")]),
                    _c("v-date-picker", {
                      staticClass: "el-def",
                      staticStyle: { width: "150px" },
                      attrs: { type: "month", "value-type": "YYYYMM" },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                    _c("b-form-input", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px", "margin-right": "50px" },
                      attrs: { placeholder: "제목" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.fetchRecords()
                        },
                      },
                      model: {
                        value: _vm.query.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "title", $$v)
                        },
                        expression: "query.title",
                      },
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search mr-50",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fetchRecords()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(" 총 [" + _vm._s(_vm.totalRecords) + "] 건 "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "id",
              items: _vm.records,
              fields: _vm.tableColumns,
              "no-local-sorting": true,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: {
              "row-selected": _vm.onSelectedRecord,
              "sort-changed": _vm.onSortingChanged,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(liveDate)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("$dateFormatter")(
                            data.item.startDate,
                            "YYYY-MM-DD"
                          )
                        ) +
                        " ~ " +
                        _vm._s(
                          _vm._f("$dateFormatter")(
                            data.item.endDate,
                            "YYYY-MM-DD"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(awardUserCount)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(data.value == null ? 0 : data.value) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(createDate)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("$dateFormatter")(data.value, "YYYY-MM-DD")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.totalRecords,
              limit: "10",
              "per-page": "5",
              align: "center",
            },
            model: {
              value: _vm.currPage,
              callback: function ($$v) {
                _vm.currPage = $$v
              },
              expression: "currPage",
            },
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [_vm._v(" 상세 ")]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetRecord()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "PlusSquareIcon" },
                      }),
                      _c("span", [_vm._v("신규 등록")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveRecord()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "SaveIcon" },
                      }),
                      _c("span", [_vm._v("저장")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("h5", [_vm._v("※ 포인트 수여는 저장 후 가능합니다.")]),
              ]),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "pointAwardRules" },
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "제목", "label-for": "title" } },
                        [
                          _c("validation-provider", {
                            attrs: { rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "title",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.record.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.record, "title", $$v)
                                        },
                                        expression: "record.title",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진행시작일",
                            "label-for": "start-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: {
                                required: true,
                                before: _vm.record.endDate,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "start-date",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.record.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.record, "startDate", $$v)
                                        },
                                        expression: "record.startDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진행종료일",
                            "label-for": "end-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: {
                                required: true,
                                after: _vm.record.startDate,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "end-date",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.record.endDate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.record, "endDate", $$v)
                                        },
                                        expression: "record.endDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "특이사항", "label-for": "memo" } },
                        [
                          _c("b-form-textarea", {
                            attrs: { id: "memo", rows: "6" },
                            model: {
                              value: _vm.record.memo,
                              callback: function ($$v) {
                                _vm.$set(_vm.record, "memo", $$v)
                              },
                              expression: "record.memo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("h5", { staticClass: "mb-50 ml-50" }, [
                        _vm._v(
                          " 수여완료 총 [" +
                            _vm._s(
                              _vm.record.users && _vm.record.users.length
                                ? _vm.record.users.length
                                : 0
                            ) +
                            "] 명 "
                        ),
                      ]),
                      _c("b-table", {
                        attrs: {
                          "primary-key": "id",
                          items: _vm.record.users,
                          fields: _vm.userTableColumns,
                          "sticky-header": "200px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(index)",
                            fn: function (data) {
                              return [
                                _vm._v(" " + _vm._s(data.index + 1) + " "),
                              ]
                            },
                          },
                          {
                            key: "cell(createDate)",
                            fn: function (data) {
                              return [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          data.value,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.record.id,
              expression: "record.id",
            },
          ],
          staticClass: "mt-2",
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [
                    _vm._v(" 포인트 수여 "),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.downloadUploadSample()
                        },
                      },
                    },
                    [_vm._v(" 업로드 샘플 다운로드 ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.awardPoint()
                        },
                      },
                    },
                    [_vm._v(" 포인트 수여하기 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("h5", [
                  _vm._v(
                    "※ 엑셀 업로드 데이터는 포인트를 수여하지 않으면 저장되지 않습니다."
                  ),
                ]),
                _c("h5", [
                  _vm._v(
                    "※ 중복 ID는 기존에 수여된 내역을 확인하지 않으며, 현재 업로드한 데이터 중 중복 ID만 삭제합니다."
                  ),
                ]),
                _c("h5", [
                  _vm._v(
                    "※ 중복 ID가 있을시 어느 포인트를 수여할지 알 수 없기 때문에, 모든 중복ID는 삭제합니다."
                  ),
                ]),
                _c("h5", [
                  _vm._v(
                    "※ 포인트 수여시 수여 내역은 자동 저장되나, 그 외 다른 항목은 저장 버튼으로 별도 저장해야 합니다."
                  ),
                ]),
                _c("h5", [
                  _vm._v(
                    "※ 수여되는 포인트는 서버에 저장된 제목으로 발행되니, 제목을 확정 및 저장 후 포인트 수여하시기 바랍니다."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c(
                "b-col",
                { staticClass: "pt-50 text-center", attrs: { md: "2" } },
                [_c("h5", [_vm._v("엑셀 업로드")])]
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("b-form-file", {
                    attrs: { accept: ".xlsx" },
                    on: { input: _vm.uploadAward },
                    model: {
                      value: _vm.recordSub.attachFile,
                      callback: function ($$v) {
                        _vm.$set(_vm.recordSub, "attachFile", $$v)
                      },
                      expression: "recordSub.attachFile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c(
                "b-col",
                { staticClass: "pt-50 text-center", attrs: { md: "2" } },
                [_c("h5", [_vm._v("엑셀 업로드 결과")])]
              ),
              _c("b-col", { staticClass: "pt-50", attrs: { md: "4" } }, [
                _c("p", [
                  _vm._v("결과 : " + _vm._s(_vm.recordSub.uploadResult)),
                ]),
                _c("p", [
                  _vm._v(
                    "유효하지않은ID(삭제) : " +
                      _vm._s(_vm.recordSub.vaildMessage)
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "중복ID(삭제) : " + _vm._s(_vm.recordSub.duplicateMessage)
                  ),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("h5", { staticClass: "mb-50 ml-50" }, [
                    _vm._v(
                      " 업로드 총 [" +
                        _vm._s(
                          _vm.recordSub.uploadUsers &&
                            _vm.recordSub.uploadUsers.length
                            ? _vm.recordSub.uploadUsers.length
                            : 0
                        ) +
                        "] 명 "
                    ),
                  ]),
                  _c("b-table", {
                    attrs: {
                      "primary-key": "id",
                      items: _vm.recordSub.uploadUsers,
                      fields: _vm.uploadTableColumns,
                      "sticky-header": "200px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(index)",
                        fn: function (data) {
                          return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }